import * as React from "react"
import { graphql } from "gatsby"
import marked from "marked"
import IssoComments from "../components/IssoComments"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div className="container-xl blog py-5">
        <div
          className="title-wrapper d-flex"
          style={{ backgroundImage: `url("${frontmatter.image}")` }}
        >
          <div className="m-auto px-5">
            <h1 className="text-center">{frontmatter.title}</h1>
            <p className="text-center mt-2">{frontmatter.short_text}</p>
          </div>
        </div>
        <div className="my-5 py-5 mx-1 mx-sm-5">
          <div
            dangerouslySetInnerHTML={{
              __html: marked(frontmatter.text),
            }}
          ></div>
        </div>
      </div>
      <div className="isso-wrapper pb-2 pt-5">
        <div className="isso-postbox">
          <h2>Laat een commentaar achter!</h2>
          <IssoComments id={frontmatter.id} />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        id
        title
        short_text
        text
        image
      }
    }
  }
`
