import React, { useEffect } from "react"

// Helper to add scripts to our page
const insertScript = (src, id, parentElement) => {
  const script = window.document.createElement("script")
  script.async = true
  script.src = src
  script.id = id
  script.dataset.title = "false"
  script.dataset.issoLang = "nl"
  script.crossOrigin = "anonymous"

  parentElement.appendChild(script)

  return script
}

// Helper to remove scripts from our page
const removeScript = (id, parentElement) => {
  const script = window.document.getElementById(id)
  if (script) {
    parentElement.removeChild(script)
  }
}

type Props = {
  id?: string
}

// The actual component
const Comments: React.FC<Props> = ({ id }) => {
  useEffect(() => {
    // If there's no window there's nothing to do for us
    if (!window) {
      return
    }
    const document = window.document
    // In case our #commento container exists we can add our commento script
    if (document.getElementById("isso-thread")) {
      insertScript(
        `https://system.imsarawoudenberg.nl/js/embed.min.js`,
        `isso-script`,
        document.body
      )
    }

    // Cleanup; remove the script from the page
    return () => removeScript(`isso-script`, document.body)
  }, [id])

  return (
    <div>
      <section
        id={`isso-thread`}
        data-title="Laat een commentaar achter!"
        data-isso-id={id}
      />
    </div>
  )
}

export default Comments
